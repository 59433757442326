<template>
  <ExternalLinkIcon class="external_link" width="15" height="15" />
</template>

<script>
import ExternalLinkIcon from 'mdi-vue/OpenInNew';

export default {
  name: 'OutboundLink',
  components: { ExternalLinkIcon },
};
</script>

<style lang="stylus">
:root
  --external_icon_color #aaa

.external_link
  color var(--external_icon_color)
  display inline
  position relative
  vertical-align middle
  top -0.2rem

</style>
